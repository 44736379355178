import {combineReducers} from 'redux'
import {chat} from './chat'
import {controls} from './controls'
import {environment} from './environment'
import {notifications} from './notifications'
import {participants} from './participants'
import {reactions} from './reactions'
import {session} from './session'
import {sidePanel} from './side-panel'
import {recorder} from './recorder'
import {modals} from './modals'
import {experiments} from './experiments'

export const rootReducer = combineReducers({
  controls,
  session,
  sidePanel,
  chat,
  participants,
  notifications,
  reactions,
  environment,
  recorder,
  modals,
  experiments,
})

export type State = ReturnType<typeof rootReducer>
