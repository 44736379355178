import {createReducer} from '@reduxjs/toolkit'
import {LiveSession, Participant, PropsV2, SessionState} from '@wix/ambassador-livevideo-server/types'
import {
  addParticipant,
  getSession,
  leaveSession,
  setDemoSession,
  markJoinedOnAnotherDevice,
  resetJoinedOnAnotherDevice,
  setJoinError,
  SESSION_ENDED_STATE,
} from '../actions/session'
import {SessionError} from '../types/errors'

export interface Session {
  token: string
  participant: Participant
  session: LiveSession
  props: PropsV2
  authorization: string
  error: SessionError
  leftSession: boolean
  joinedOnAnotherDevice: boolean
  rejoining: boolean
}

const defaultSession = {} as Session

export const session = createReducer(defaultSession, builder => {
  builder
    .addCase(markJoinedOnAnotherDevice, state => {
      state.joinedOnAnotherDevice = true
    })
    .addCase(resetJoinedOnAnotherDevice, state => {
      state.joinedOnAnotherDevice = false
    })
    .addCase(addParticipant.fulfilled, (state, action) => {
      state.props = action.payload.propsV2
      state.session = action.payload.session
      state.participant = action.payload.participant
      state.authorization = action.payload.authorization
      state.leftSession = false
      state.rejoining = false
    })
    .addCase(getSession.fulfilled, (state, action) => {
      state.session = action.payload.session
      state.participant = action.payload.participant
    })
    .addCase(setDemoSession.fulfilled, (state, action) => {
      state.session = action.payload.session
      state.participant = action.payload.participant
    })
    .addCase(getSession.rejected, state => {
      state.error = SessionError.NO_SESSION
    })
    .addCase(setJoinError, state => {
      state.error = SessionError.JOIN_FAILURE
    })
    .addCase(leaveSession.fulfilled, (state, action) => {
      state.props = null
      state.authorization = null
      state.leftSession = !action.payload.rejoining
      state.rejoining = !!action.payload.rejoining
    })
    .addCase(addParticipant.rejected, (state, action) => {
      if (action.error.message === SESSION_ENDED_STATE) {
        state.session.sessionState = SessionState.ENDED
      }
    })
})
