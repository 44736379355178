import {createAction} from '@reduxjs/toolkit'
import {ListParticipantsResponse} from '@wix/ambassador-livevideo-server/http'
import type {ReceiveParticipantProps, ReceiveParticipantStatusProps} from '@wix/live-video-components'
import {delay} from '@wix/live-video-commons'
import {getMyName, getMyParticipantId, isMeeting} from '../selectors/session'
import {getParticipant, isParticipantActive, isParticipantRecorder} from '../selectors/participants'
import {createAsyncAction} from '../utils/redux'
import {
  pushNotification,
  cancelJoinedNotification,
  NotificationType,
  NotificationImage,
  flushJoinedNotifications,
} from './notifications'
import {markNotRecording} from './recorder'
import {addParticipant, leaveSession} from './session'

const REJOIN_DELAY = 5000

const UPDATE_PARTICIPANT_RETRIES = 5
const UPDATE_PARTICIPANT_RETRY_DELAY = 2000

export const listParticipants = createAsyncAction<ListParticipantsResponse>(
  'LIST_PARTICIPANTS',
  (_, {extra: {api}, getState}) => api.listParticipants(getState().session.authorization),
)

export const updateParticipant = createAsyncAction<ListParticipantsResponse, {participantId: string; retries?: number}>(
  'UPDATE_PARTICIPANT',
  async ({participantId, retries = UPDATE_PARTICIPANT_RETRIES}, {extra: {api}, getState, dispatch}) => {
    const result = await api.listParticipants(getState().session.authorization, [participantId])

    const hasClientId = Boolean(result.participants?.[0]?.clientId)

    if (!hasClientId && retries) {
      setTimeout(() => {
        dispatch(updateParticipant({participantId, retries: retries - 1}))
      }, UPDATE_PARTICIPANT_RETRY_DELAY)

      // Reject async action in case of failure
      throw new Error(`Participant ${participantId} did not have clientId`)
    }

    dispatch(flushJoinedNotifications({participants: result.participants}))

    return result
  },
)

export const setParticipantActivity = createAsyncAction<ReceiveParticipantProps, ReceiveParticipantProps>(
  'SET_PARTICIPANT_ACTIVITY',
  ({participantId, present}, {dispatch, getState, extra: {t}}) => {
    const state = getState()

    // Joining with personal link when user has already joined, special case - remove and delay join
    // OR unstable internet connection. After connection is restored - reconnect to avoid video problems
    if (present && participantId === getMyParticipantId(state) && isParticipantActive(state, participantId)) {
      dispatch(rejoinSession())
      present = false
    }

    if (!present) {
      const participant = getParticipant(state.participants.participants, participantId)

      dispatch(cancelJoinedNotification(participantId))

      if (isParticipantRecorder(participant)) {
        dispatch(markNotRecording())

        dispatch(
          pushNotification({
            id: participantId,
            participantId,
            prependName: false,
            type: NotificationType.TEXT,
            image: NotificationImage.RECORDING,
            text: t('recordingStopped'),
            visible: true,
          }),
        )
      } else if (isMeeting(state)) {
        dispatch(
          pushNotification({
            id: participantId,
            participantId,
            prependName: true,
            type: NotificationType.TEXT,
            text: t('justLeft'),
            visible: true,
          }),
        )
      }
    }

    return {participantId, present}
  },
)

export const rejoinSession = createAsyncAction('REJOIN_SESSION', async (_, {dispatch, getState}) => {
  const state = getState()
  const name = getMyName(state)
  await dispatch(leaveSession({rejoining: true}))
  await delay(REJOIN_DELAY)
  await dispatch(addParticipant({name}))
})

export const setParticipantStatus = createAction<ReceiveParticipantStatusProps>('SET_PARTICIPANT_STATUS')

export type UpdateParticipant = typeof updateParticipant
export type ListParticipants = typeof listParticipants
export type SetParticipantActivity = typeof setParticipantActivity
export type SetParticipantStatus = typeof setParticipantStatus
