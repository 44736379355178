import {LivevideoServer} from '@wix/ambassador-livevideo-server/http'
import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import type {ControllerParams} from '@wix/yoshi-flow-editor'

interface CreateApiProps {
  baseUrl: string
  getAuthorization: () => string
}

export const createApi = ({baseUrl, getAuthorization}: CreateApiProps) => {
  const liveVideoServer = LivevideoServer(`${baseUrl}/_api/livevideo-server`)

  return {
    getSession: async (id: string, token: string) =>
      liveVideoServer
        .LiveSessionService()({authorization: getAuthorization()})
        .describeEntrance({sessionId: id, token}),
    addParticipant: async (sessionId: string, token: string, name: string) =>
      liveVideoServer
        .LiveSessionService()({authorization: getAuthorization()})
        .addParticipant({sessionId, token, name}),
    sendChatMessage: async (participantAuthorization: string, message: string) =>
      liveVideoServer
        .ChatService()({authorization: getAuthorization()})
        .sendChatMessage({authorization: participantAuthorization, body: message}),
    getChatHistory: async (participantAuthorization: string, chatMessageId: string, limit: number) =>
      liveVideoServer
        .ChatService()({authorization: getAuthorization()})
        .listChatMessages({authorization: participantAuthorization, messageId: chatMessageId, limit}),
    listParticipants: (participantAuthorization: string, participantIds?: string[]) =>
      liveVideoServer
        .LiveSessionService()({authorization: getAuthorization()})
        .listParticipants({authorization: participantAuthorization, participantId: participantIds}),
    sendReaction: (participantAuthorization: string, emoji: string) =>
      liveVideoServer
        .ReactionsService()({authorization: getAuthorization()})
        .react({authorization: participantAuthorization, emoji}),
    listReactions: (participantAuthorization: string) =>
      liveVideoServer
        .ReactionsService()({authorization: getAuthorization()})
        .listReactions({authorization: participantAuthorization, facet: ['emoji'], offset: 0, limit: 100}),
  }
}

export const getBaseUrl = (controllerParams: ControllerParams, fallback = '') => {
  if (controllerParams.flowAPI.environment.isSSR) {
    return parseBaseUrl(controllerParams.controllerConfig.wixCodeApi).root
  } else {
    return fallback
  }
}

export const parseBaseUrl = (wixCodeApi: IWixAPI) => {
  const baseUrl = wixCodeApi.location.baseUrl
  const baseUrlParts = baseUrl.split('/')
  return {
    root: baseUrlParts.slice(0, 3).join('/'),
    relative: baseUrlParts.slice(3).join('/'),
  }
}

export type Api = ReturnType<typeof createApi>
