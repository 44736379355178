import {createReducer} from '@reduxjs/toolkit'
import {
  enableJoinNotifications,
  disableJoinNotifications,
  fadeNotification,
  NotificationImage,
  NotificationType,
  pushNotification,
  scheduleJoinedNotification,
  flushJoinedNotifications,
  cancelJoinedNotification,
} from '../actions/notifications'

export interface NotificationsState {
  notifications: Notification[]
  pendingJoined: string[]
  joinNotificationsEnabled: boolean
}

export interface Notification {
  id: string
  type: NotificationType
  text: string
  participantId: string
  prependName: boolean
  image?: NotificationImage
  visible: boolean
  fading?: boolean
  reactionCode?: string
}

const initialState: NotificationsState = {
  notifications: [],
  pendingJoined: [],
  joinNotificationsEnabled: false,
}

export const notifications = createReducer(initialState, builder => {
  builder
    .addCase(enableJoinNotifications, state => {
      state.joinNotificationsEnabled = true
    })
    .addCase(disableJoinNotifications, state => {
      state.joinNotificationsEnabled = false
    })
    .addCase(pushNotification.pending, (state, action) => {
      state.notifications.push(action.meta.arg)
    })
    .addCase(fadeNotification, (state, {payload: id}) => {
      const newNotifications = state.notifications
        .filter(notification => notification.visible)
        .map(notification =>
          notification.id === id
            ? {
                ...notification,
                fading: true,
              }
            : notification,
        )

      state.notifications = newNotifications
    })
    .addCase(pushNotification.fulfilled, (state, action) => {
      const newNotifications = state.notifications
        .filter(notification => notification.visible)
        .map(notification =>
          notification.id === action.meta.arg.id
            ? {
                ...notification,
                visible: false,
              }
            : notification,
        )

      state.notifications = newNotifications
    })
    .addCase(scheduleJoinedNotification, (state, {payload: participantId}) => {
      state.pendingJoined.push(participantId)
    })
    .addCase(flushJoinedNotifications.fulfilled, (state, {payload: participantIds}) => {
      state.pendingJoined = state.pendingJoined.filter(participantId => !participantIds.includes(participantId))
    })
    .addCase(cancelJoinedNotification, (state, {payload: cancelledParticipantId}) => {
      state.pendingJoined = state.pendingJoined.filter(participantId => participantId !== cancelledParticipantId)
    })
})
