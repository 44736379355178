import {createReducer} from '@reduxjs/toolkit'
import {receiveChatMessage} from '../actions/chat'
import {toggleSidePanel, openSidePanel} from '../actions/side-panel'

export enum SidePanelContent {
  NAME = 'NAME',
  PARTICIPANTS = 'PARTICIPANTS',
  BACKGROUND = 'BACKGROUND',
  CAMERA_AND_SOUND = 'CAMERA_AND_SOUND',
  CHAT = 'CHAT',
  MOBILE_SETTINGS = 'MOBILE_SETTINGS',
}

export interface SidePanelState {
  open: boolean
  content: SidePanelContent
  chatMessagesPending: boolean
}

const initialState: SidePanelState = {
  open: false,
  content: null,
  chatMessagesPending: false,
}

export const sidePanel = createReducer(initialState, builder => {
  builder.addCase(toggleSidePanel, (state, action) => {
    const open = action.payload !== state.content
    return {
      open: action.payload === undefined ? false : open,
      content: open ? action.payload : null,
      chatMessagesPending: open && action.payload === SidePanelContent.CHAT ? false : state.chatMessagesPending,
    }
  })
  builder.addCase(openSidePanel, (state, action) => ({
    ...state,
    open: true,
    content: action.payload,
  }))
  builder.addCase(receiveChatMessage, state => {
    if (!state.open || state.content !== SidePanelContent.CHAT) {
      state.chatMessagesPending = true
    }
  })
})
