import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import {bindActionCreators} from 'redux'
import type {CreateControllerFn} from '@wix/yoshi-flow-editor'
import {createApi, getBaseUrl} from './services/api'
import {authorizationGetter, onAuthorizationChanged} from './services/authorization'
import {isTokenValid} from './utils/token'
import {
  addPendingChatMessage,
  deleteChatMessage,
  fetchChatHistory,
  receiveChatMessage,
  removePendingChatMessage,
} from './Widget/actions/chat'
import {
  setLayout,
  setAudio,
  setFullScreen,
  setVideo,
  markScreenShared,
  markScreenShareLoading,
} from './Widget/actions/controls'
import {navigateToMainPage} from './Widget/actions/navigation'
import {
  pushNotification,
  scheduleJoinedNotification,
  flushJoinedNotifications,
  cancelJoinedNotification,
  enableJoinNotifications,
  disableJoinNotifications,
} from './Widget/actions/notifications'
import {
  listParticipants,
  setParticipantActivity,
  setParticipantStatus,
  updateParticipant,
} from './Widget/actions/participants'
import {
  addParticipant,
  getSession,
  leaveSession,
  setDemoSession,
  markJoinedOnAnotherDevice,
  resetJoinedOnAnotherDevice,
  setJoinError,
} from './Widget/actions/session'
import {setEnvironment} from './Widget/actions/environment'
import {toggleSidePanel, openSidePanel} from './Widget/actions/side-panel'
import {WidgetProps} from './Widget/interfaces'
import {rootReducer} from './Widget/reducers'
import {isSessionNotStarted, isSessionStarted} from './Widget/selectors/session'
import {receiveReaction, listReactions, sendReaction} from './Widget/actions/reactions'
import {showModal, hideModal} from './Widget/actions/modals'

const createController: CreateControllerFn = async controller => {
  return {
    async pageReady() {
      const baseUrl = getBaseUrl(controller)
      const getAuthorization = authorizationGetter(controller)
      const api = createApi({baseUrl, getAuthorization})
      const {wixCodeApi, platformAPIs, appParams} = controller.controllerConfig
      const {site, location} = wixCodeApi
      const locale = site.regionalSettings
      const currentSessionId = location.path[location.path.length - 1]
      const {t: token} = location.query
      const {visitorId, ownerId, metaSiteId} = platformAPIs.bi
      const instanceId = appParams.instanceId
      const experiments = controller.flowAPI.experiments.all()

      const store = configureStore({
        reducer: rootReducer,
        middleware: [
          ...getDefaultMiddleware({thunk: {extraArgument: {api, wixCodeApi, t: controller.flowAPI.translations.t}}}),
        ],
        preloadedState: {
          experiments,
          session: {
            token,
          } as any,
        },
      })

      const setProps = (props: Partial<WidgetProps>) => {
        controller.controllerConfig.setProps({...props, fitToContentHeight: true})
      }

      const actions = bindActionCreators(
        {
          setDemoSession,
          getSession,
          leaveSession,
          setVideo,
          setAudio,
          toggleSidePanel,
          openSidePanel,
          setFullScreen,
          setLayout,
          addPendingChatMessage,
          removePendingChatMessage,
          receiveChatMessage,
          deleteChatMessage,
          fetchChatHistory,
          addParticipant,
          listParticipants,
          updateParticipant,
          setParticipantActivity,
          setParticipantStatus,
          pushNotification,
          receiveReaction,
          listReactions,
          sendReaction,
          setEnvironment,
          navigateToMainPage,
          markScreenShared,
          markScreenShareLoading,
          markJoinedOnAnotherDevice,
          resetJoinedOnAnotherDevice,
          scheduleJoinedNotification,
          flushJoinedNotifications,
          cancelJoinedNotification,
          enableJoinNotifications,
          disableJoinNotifications,
          setJoinError,
          showModal,
          hideModal,
        },
        store.dispatch,
      )
      actions.setEnvironment({locale, visitorId: visitorId || ownerId, instanceId, metaSiteId})

      const {
        environment: {isSSR, isPreview, isEditor},
      } = controller.flowAPI

      if (isEditor || isPreview) {
        actions.setDemoSession()
      } else {
        await (isTokenValid(token) ? actions.getSession({id: currentSessionId, token}) : null)

        const waitingForBroadcast = isSessionNotStarted(store.getState())

        if (!isSSR && waitingForBroadcast && token) {
          const intervalId = setInterval(async () => {
            try {
              await actions.getSession({id: currentSessionId, token})
            } catch (e) {}
            if (isSessionStarted(store.getState())) {
              clearInterval(intervalId)
            }
          }, 5000)
        }
      }

      setProps({
        actions,
        authorization: getAuthorization(),
        baseUrl,
        ...store.getState(),
      })

      subscribeToStoreChange(store, setProps)

      onAuthorizationChanged(controller, instance => setProps({authorization: instance}))
    },
    updateAppSettings: async ($w, action) => {
      if (action.type === 'forceShow') {
        controller.controllerConfig.setProps({forcedScreen: action.screen})
      }
    },
  }
}

const subscribeToStoreChange = (store, setProps) => {
  let previousState = store.getState()

  store.subscribe(() => {
    const newState = store.getState()
    const stateDiff = {}
    Object.entries(newState).forEach(([key, value]) => {
      if (value !== previousState[key]) {
        stateDiff[key] = value
      }
    })
    if (Object.keys(stateDiff).length) {
      setProps(stateDiff)
    }
    previousState = newState
  })
}

export default createController
