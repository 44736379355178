import {createAction} from '@reduxjs/toolkit'
import {
  AddParticipantResponse,
  DescribeEntranceResponse,
  SessionType,
  SessionState,
  SessionFlag,
} from '@wix/ambassador-livevideo-server/types'
import {HandledAsyncActionError} from '@wix/live-video-commons'
import {getMyParticipantId} from '../selectors/session'
import {ModalType} from '../interfaces'
import {createAsyncAction} from '../utils/redux'
import {showModal} from './modals'

export const markJoinedOnAnotherDevice = createAction('JOINED_ON_ANOTHER_DEVICE')

export const resetJoinedOnAnotherDevice = createAction('RESET_JOINED_ON_ANOTHER_DEVICE')

export const setJoinError = createAction('SET_JOIN_ERROR')

export const SESSION_ENDED_STATE = 'SESSION_ENDED'

export const getSession = createAsyncAction<DescribeEntranceResponse, {id: string; token: string}>(
  'GET_SESSION',
  ({id, token}, {extra: {api}}) => api.getSession(id, token),
)

export const setDemoSession = createAsyncAction<DescribeEntranceResponse, undefined>(
  'SET_DEMO_SESSION',
  (_, {extra: {t}}) => {
    return {
      participant: {
        externalEntranceId: null,
        name: t('demoState.waitingRoom.participantName'),
        participantId: null,
        sessionId: null,
      },
      session: {
        id: null,
        sessionState: SessionState.STARTED,
        sessionType: SessionType.MEETING,
        duration: 60,
        name: t('demoState.waitingRoom.title'),
        startDate: new Date().toISOString() as any,
      },
    }
  },
)

export const leaveSession = createAsyncAction<
  {myParticipantId: string; rejoining: boolean},
  {rejoining?: boolean} | void
>('LEAVE_SESSION', async (params, {getState}) => {
  const {rejoining} = params || {rejoining: false}
  return Promise.resolve({myParticipantId: getMyParticipantId(getState()), rejoining})
})

export const addParticipant = createAsyncAction<AddParticipantResponse, {name: string}>(
  'ADD_PARTICIPANT',
  async ({name}, {extra: {api}, getState, dispatch}) => {
    const state = getState()
    const {
      token,
      session: {id, sessionFlags = []},
    } = state.session

    const testSession = sessionFlags.includes(SessionFlag.TEST)

    try {
      return await api.addParticipant(id, token, name)
    } catch (e: any) {
      if (testSession) {
        dispatch(showModal(ModalType.TEST_VIDEO_PARTICIPANT_LIMIT))
        throw new HandledAsyncActionError(e)
      }
      if (e?.response?.details?.applicationError?.code === SESSION_ENDED_STATE) {
        throw new HandledAsyncActionError(e.response.details.applicationError.code)
      } else {
        throw e
      }
    }
  },
)

export type MarkJoinedOnAnotherDevice = typeof markJoinedOnAnotherDevice
export type ResetJoinedOnAnotherDevice = typeof resetJoinedOnAnotherDevice
export type GetSession = typeof getSession
export type LeaveSession = typeof leaveSession
export type AddParticipant = typeof addParticipant
export type SetDemoSession = typeof setDemoSession
export type SetJoinError = typeof setJoinError
