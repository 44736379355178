import {createReducer} from '@reduxjs/toolkit'
import {Reaction} from '@wix/ambassador-livevideo-server/http'
import {listReactions, receiveReaction} from '../actions/reactions'

export interface ReactionsState {
  reactions: Reaction[]
}

const initialState: ReactionsState = {
  reactions: [],
}

export const reactions = createReducer(initialState, builder => {
  builder
    .addCase(listReactions.fulfilled, (state, action) => ({
      reactions: action.payload.reactions,
    }))
    .addCase(receiveReaction, (state, action: {payload: Reaction}) => {
      state.reactions.push(action.payload)
    })
})
