import {createReducer} from '@reduxjs/toolkit'
import {Participant, Reaction} from '@wix/ambassador-livevideo-server/types'
import {
  listParticipants,
  setParticipantActivity,
  setParticipantStatus,
  updateParticipant,
} from '../actions/participants'
import {receiveReaction} from '../actions/reactions'
import {leaveSession} from '../actions/session'

export interface ParticipantStatus {
  muted: boolean
  video: boolean
  screenshare: boolean
  reaction: Reaction
}

export interface ParticipantsState {
  participants: Participant[]
  active: string[]
  status: {[participantId: string]: ParticipantStatus}
}

const defaultParticipantStatus: ParticipantStatus = {
  video: false,
  muted: true,
  screenshare: false,
  reaction: null,
}

const initialState: ParticipantsState = {
  participants: [],
  active: [],
  status: {},
}

export const participants = createReducer(initialState, builder => {
  builder
    .addCase(listParticipants.fulfilled, (state, {payload}) => {
      state.participants = payload.participants
    })
    .addCase(updateParticipant.fulfilled, (state, {payload}) => {
      const updatedParticipant = payload.participants[0]

      let alreadyExisted = false

      if (updatedParticipant) {
        state.participants = state.participants.map(participant => {
          if (participant.participantId === updatedParticipant.participantId) {
            alreadyExisted = true
            return updatedParticipant
          }

          return participant
        })
      }

      if (!alreadyExisted) {
        state.participants.push(updatedParticipant)
      }
    })
    .addCase(setParticipantActivity.fulfilled, (state, {payload: {participantId, present}}) => {
      if (present) {
        if (!state.active.includes(participantId)) {
          state.active.push(participantId)
        }
      } else {
        if (state.active.includes(participantId)) {
          state.active.splice(state.active.indexOf(participantId), 1)
        }
      }
    })
    .addCase(setParticipantStatus, (state, {payload}) => {
      state.status[payload.participantId] = {
        ...defaultParticipantStatus,
        ...state.status[payload.participantId],
        ...payload,
      }
    })
    .addCase(receiveReaction, (state, {payload}: {payload: Reaction}) => {
      state.status[payload.participantId] = {
        ...defaultParticipantStatus,
        ...state.status[payload.participantId],
        reaction: payload,
      }
    })
    .addCase(leaveSession.fulfilled, (state, {payload: {myParticipantId}}) => {
      state.active.splice(state.active.indexOf(myParticipantId), 1)
    })
})
