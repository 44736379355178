import {createAsyncAction} from '../utils/redux'

export const navigateToMainPage = createAsyncAction('NAVIGATE_TO_MAIN_PAGE', async (_, {extra: {wixCodeApi}}) => {
  const {pages} = await wixCodeApi.site.getSiteStructure()
  const {url} = pages.find(page => page.isHomePage)

  wixCodeApi.location.to(url)
})

export type NavigateToMainPage = typeof navigateToMainPage
