import {Participant, ParticipantState} from '@wix/ambassador-livevideo-server/types'
import {sortBy} from '@wix/live-video-commons'
import type {TFunction} from '@wix/yoshi-flow-editor'
import type {State} from '../reducers'

export const isParticipantRecorder = (participant: Participant) =>
  (participant?.state || []).includes(ParticipantState.VIDEO_RECORDER)

export const isParticipantHidden = (participant: Participant) =>
  (participant?.state || []).includes(ParticipantState.HIDDEN)

export const getActiveParticipants = (state: State, includeHidden = false) => {
  const {participants} = state.participants
  return Object.values(participants)
    .filter(participant => isParticipantActive(state, participant.participantId))
    .filter(participant => includeHidden || !isParticipantHidden(participant))
    .sort(sortBy('name'))
}

export const isParticipantActive = (state: State, participantId: string) => {
  const {active} = state.participants
  return active.includes(participantId)
}

export const getParticipantName = (participants: Participant[], participantId: string) =>
  getParticipant(participants, participantId)?.name ?? 'Unknown Name'

export const getParticipant = (participants: Participant[], participantId: string) =>
  participants.find(participant => participant.participantId === participantId)

export const getLabeledParticipantName = (
  participants: Participant[],
  participantId: string,
  myParticipantId: string,
  t: TFunction,
) => {
  const name = getParticipantName(participants, participantId)
  const label = getParticipantLabel(participants, participantId, myParticipantId, t)
  return `${name}${label ? ` (${label})` : ''}`
}

export const getParticipantLabel = (
  participants: Participant[],
  participantId: string,
  myParticipantId: string,
  t: TFunction,
) => {
  const participant = getParticipant(participants, participantId)

  if (participantId === myParticipantId) {
    return t('participants.label.me')
  } else if (isHost(participant)) {
    return t('participants.label.host')
  }
}

export const getInitialParticipantName = (state: State) => state.session?.participant?.name ?? ''

export const getParticipantStatus = (state: State, participantId: string) => state.participants.status[participantId]

export const getActiveParticipantCount = (state: State) => getActiveParticipants(state).length

export const getActiveHostParticipants = (state: State) => getActiveParticipants(state).filter(isHost)

export const getActiveGuestParticipants = (state: State) =>
  getActiveParticipants(state).filter(participant => !isHost(participant))

export const getKnownClients = (state: State) =>
  getActiveParticipants(state, true).map(participant => ({
    clientId: participant.clientId,
    isHost: isHost(participant),
    isHidden: isParticipantHidden(participant),
  }))

export const getActiveHostParticipantsClientIds = (state: State) =>
  getActiveHostParticipants(state).map(participant => participant.clientId)

export const isHost = (participant: Participant) => Boolean(participant?.state.includes(ParticipantState.MANAGER))
