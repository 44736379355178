import {createAction} from '@reduxjs/toolkit'
import {LivevideoListReactionsResponse, Reaction, ReactResponse} from '@wix/ambassador-livevideo-server/types'
import {createAsyncAction} from '../utils/redux'

export const listReactions = createAsyncAction<LivevideoListReactionsResponse>(
  'LIST_REACTIONS',
  (_, {extra: {api}, getState}) => {
    const state = getState()
    return api.listReactions(state.session.authorization)
  },
)

export const sendReaction = createAsyncAction<ReactResponse, string>(
  'SEND_REACTION',
  (emoji, {extra: {api}, getState}) => {
    const state = getState()
    return api.sendReaction(state.session.authorization, emoji)
  },
)

export const receiveReaction = createAction<Reaction>('RECEIVE_REACTION')

export type ListReactions = typeof listReactions
export type SendReaction = typeof sendReaction
export type ReceiveReaction = typeof receiveReaction
