import {createAction} from '@reduxjs/toolkit'
import {delay} from '@wix/live-video-commons'
import {Participant} from '@wix/ambassador-livevideo-server/types'
import type {Notification} from '../reducers/notifications'
import {isParticipantHidden, isParticipantRecorder} from '../selectors/participants'
import {isMeeting} from '../selectors/session'
import {createAsyncAction} from '../utils/redux'
import {markRecording} from './recorder'

// Remember to change animation delay in notification.scss if you choose to change fade delay
export const NOTIFICATION_CSS_FADE_DURATION = 5000
export const NOTIFICATION_SHOW_DURATION = 1000

export enum NotificationType {
  TEXT,
  REACTION,
}

export enum NotificationImage {
  RECORDING,
}

export const enableJoinNotifications = createAction('ENABLE_JOIN_NOTIFICATIONS')
export const disableJoinNotifications = createAction('DISABLE_JOIN_NOTIFICATIONS')

export const pushNotification = createAsyncAction<void, Notification>('PUSH_NOTIFICATION', async ({id}, {dispatch}) => {
  await delay(NOTIFICATION_SHOW_DURATION)
  dispatch(fadeNotification(id))
  await delay(NOTIFICATION_CSS_FADE_DURATION)
})

export const fadeNotification = createAction<string>('FADE_NOTIFICATION')

export const scheduleJoinedNotification = createAction<string>('SCHEDULE_JOINED_NOTIFICATION')

export const flushJoinedNotifications = createAsyncAction<string[], {participants: Participant[]}>(
  'FLUSH_JOINED_NOTIFICATIONS',
  ({participants}, {dispatch, getState, extra: {t}}) => {
    const state = getState()

    const {
      notifications: {pendingJoined, joinNotificationsEnabled},
    } = state

    let resolvedNotifications = []

    if (isMeeting(state)) {
      const cancelledNotifications = pendingJoined.filter(participantId =>
        participants.find(
          participant =>
            participant.participantId === participantId &&
            isParticipantHidden(participant) &&
            !isParticipantRecorder(participant),
        ),
      )

      cancelledNotifications.forEach(participantId => {
        dispatch(cancelJoinedNotification(participantId))
      })

      resolvedNotifications = pendingJoined.filter(participantId =>
        participants.find(
          participant =>
            participant.participantId === participantId &&
            !cancelledNotifications.includes(participant.participantId) &&
            !isParticipantRecorder(participant),
        ),
      )

      if (joinNotificationsEnabled) {
        resolvedNotifications.forEach(participantId => {
          dispatch(
            pushNotification({
              id: participantId,
              participantId,
              prependName: true,
              type: NotificationType.TEXT,
              text: t('joinedMeeting'),
              visible: true,
            }),
          )
        })
      }
    }

    const recorder = participants.find(
      participant => pendingJoined.includes(participant.participantId) && isParticipantRecorder(participant),
    )

    if (recorder) {
      resolvedNotifications.push(recorder.participantId)

      dispatch(markRecording())

      if (joinNotificationsEnabled) {
        dispatch(
          pushNotification({
            id: recorder.participantId,
            participantId: recorder.participantId,
            prependName: false,
            type: NotificationType.TEXT,
            image: NotificationImage.RECORDING,
            text: t('recordingStarted'),
            visible: true,
          }),
        )
      }
    }

    return resolvedNotifications
  },
)

export const cancelJoinedNotification = createAction<string>('CANCEL_JOINED_NOTIFICATION')

export type EnableJoinNotifications = typeof enableJoinNotifications
export type DisableJoinNotifications = typeof disableJoinNotifications
export type PushNotification = typeof pushNotification
export type ScheduleJoinedNotification = typeof scheduleJoinedNotification
export type FlushJoinedNotifications = typeof flushJoinedNotifications
export type CancelJoinedNotification = typeof cancelJoinedNotification
