import {createAction} from '@reduxjs/toolkit'
import type {ChatMessage, LivevideoListChatMessagesResponse} from '@wix/ambassador-livevideo-server/types'
import {createAsyncAction} from '../utils/redux'

const CHAT_HISTORY_FETCH_LIMIT = 50

export const addPendingChatMessage = createAction<ChatMessage>('ADD_PENDING_CHAT_MESSAGE')
export const removePendingChatMessage = createAction<string>('REMOVE_PENDING_CHAT_MESSAGE')
export const receiveChatMessage = createAction<{message: ChatMessage; pendingId?: string}>('RECEIVE_CHAT_MESSAGE')
export const deleteChatMessage = createAction<string>('DELETE_CHAT_MESSSAGE')

export const fetchChatHistory = createAsyncAction<LivevideoListChatMessagesResponse>(
  'FETCH_CHAT_HISTORY',
  (_, {extra: {api}, getState}) => {
    const state = getState()
    const earliestMessageId = state.chat.messages.length ? state.chat.messages[0].id : null
    return api.getChatHistory(state.session.authorization, earliestMessageId, CHAT_HISTORY_FETCH_LIMIT)
  },
)

export type AddPendingChatMessage = typeof addPendingChatMessage
export type RemovePendingChatMessage = typeof removePendingChatMessage
export type ReceiveChatMessage = typeof receiveChatMessage
export type FetchChatHistory = typeof fetchChatHistory
export type DeleteChatMessage = typeof deleteChatMessage
