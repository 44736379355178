import jwt from 'jwt-decode'

export const isTokenValid = (token: string) => {
  try {
    jwt(token)
    return true
  } catch (e) {
    console.error(`Invalid token provided: ${token}`)
    return false
  }
}
