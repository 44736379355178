import {createReducer} from '@reduxjs/toolkit'
import type {ChatMessage} from '@wix/ambassador-livevideo-server/types'
import {sortBy, sortedUniqBy} from 'lodash'
import {
  addPendingChatMessage,
  deleteChatMessage,
  fetchChatHistory,
  receiveChatMessage,
  removePendingChatMessage,
} from '../actions/chat'

export interface Chat {
  messages: ChatMessage[]
  hasMoreHistory: Boolean
  fetchingHistory: Boolean
}

const initialState: Chat = {
  messages: [],
  hasMoreHistory: true,
  fetchingHistory: false,
}

export const chat = createReducer(initialState, builder => {
  builder.addCase(addPendingChatMessage, (state, {payload}) => {
    state.messages.push(payload)
  })
  builder.addCase(removePendingChatMessage, (state, {payload}) => {
    state.messages = removeMessageById(state.messages, payload)
  })
  builder.addCase(receiveChatMessage, (state, {payload: {message, pendingId}}) => {
    if (pendingId) {
      state.messages = removeMessageById(state.messages, pendingId)
    }
    state.messages.push(message)
    state.messages = sortedUniqBy(sortBy(state.messages, 'id'), 'id')
  })
  builder.addCase(deleteChatMessage, (state, {payload}) => {
    state.messages = removeMessageById(state.messages, payload)
  })
  builder.addCase(fetchChatHistory.pending, (state, action) => {
    state.fetchingHistory = true
  })
  builder.addCase(fetchChatHistory.rejected, (state, action) => {
    state.fetchingHistory = false
  })
  builder.addCase(fetchChatHistory.fulfilled, (state, action) => {
    if (action.payload.messages.length) {
      state.messages = sortBy([...action.payload.messages, ...state.messages], 'id')
    } else {
      state.hasMoreHistory = false
    }
    state.fetchingHistory = false
  })
})

const removeMessageById = (messages: ChatMessage[], id) => {
  return messages.filter(msg => msg.id !== id)
}
