import {createAction} from '@reduxjs/toolkit'
import type {LAYOUT} from '@wix/live-video-commons'

export const setVideo = createAction<boolean>('SET_VIDEO')
export const setAudio = createAction<boolean>('SET_AUDIO')
export const markScreenShared = createAction<boolean>('MARK_SCREEN_SHARED')
export const markScreenShareLoading = createAction<boolean>('MARK_SCREEN_SHARE_LOADING')
export const setFullScreen = createAction<boolean>('SET_FULL_SCREEN')
export const setLayout = createAction<LAYOUT>('SET_LAYOUT')

export type SetVideo = typeof setVideo
export type SetAudio = typeof setAudio
export type MarkScreenShared = typeof markScreenShared
export type MarkScreenShareLoading = typeof markScreenShareLoading
export type SetFullScreen = typeof setFullScreen
export type SetLayout = typeof setLayout
