import {ParticipantState, SessionType, SessionState} from '@wix/ambassador-livevideo-server/types'
import type {State} from '../reducers'

export const isSessionLoaded = (state: State) => Boolean(state.session.session)

export const isSessionConnected = (state: State) => Boolean(state.session.props)

export const isWebinar = (state: State) => state.session.session.sessionType === SessionType.WEBINAR

export const isMeeting = (state: State) => state.session.session.sessionType === SessionType.MEETING

export const getSession = (state: State) => state.session.session

export const isParticipantBanned = (state: State) =>
  state.session?.participant?.state?.includes(ParticipantState.BANNED)

export const isParticipantJoinedElsewhere = (state: State) => Boolean(state.session?.joinedOnAnotherDevice)

export const isParticipantRejoining = (state: State) => Boolean(state.session?.rejoining)

export const isParticipantVideoRecorder = (state: State) =>
  state.session?.participant?.state?.includes(ParticipantState.VIDEO_RECORDER)

export const isParticipantVideoStreamer = (state: State) =>
  state.session?.participant?.state?.includes(ParticipantState.VIDEO_STREAMER)

export const getSessionState = (state: State) => state.session?.session?.sessionState

export const isSessionNotStarted = (state: State) => getSessionState(state) === SessionState.NOT_STARTED

export const isSessionStarted = (state: State) => getSessionState(state) === SessionState.STARTED

export const isSessionEnded = (state: State) => getSessionState(state) === SessionState.ENDED

export const getMyParticipantId = (state: State) => state.session.participant?.participantId

export const getMyName = (state: State) => state.session.participant?.name

export const hasLeftSession = (state: State) => Boolean(state.session.leftSession)

export const isWaitingScreen = (state: State) =>
  !isSessionConnected(state) && !isSessionEnded(state) && !hasLeftSession(state)

export const getSessionDuplexerConfig = (state: State) => state.session?.session?.messagingChannel?.[0]
