import {createReducer} from '@reduxjs/toolkit'
import type {LAYOUT} from '@wix/live-video-commons'
import {
  markScreenShared,
  markScreenShareLoading,
  setAudio,
  setFullScreen,
  setLayout,
  setVideo,
} from '../actions/controls'

interface Controls {
  video: boolean
  audio: boolean
  fullScreen: boolean
  screenShareLoading: boolean
  screenShared: boolean
  layout: LAYOUT
}

const initialState: Controls = {
  video: true,
  audio: true,
  fullScreen: false,
  screenShareLoading: false,
  screenShared: false,
  layout: 'GRID' as LAYOUT,
}

export const controls = createReducer(initialState, builder => {
  builder
    .addCase(setVideo, (state, {payload}) => {
      state.video = payload
    })
    .addCase(setAudio, (state, {payload}) => {
      state.audio = payload
    })
    .addCase(setFullScreen, (state, {payload}) => {
      state.fullScreen = payload
    })
    .addCase(markScreenShareLoading, (state, {payload}) => {
      state.screenShareLoading = payload
    })
    .addCase(markScreenShared, (state, {payload}) => {
      state.screenShared = payload
    })
    .addCase(setLayout, (state, {payload}) => {
      state.layout = payload
    })
})
