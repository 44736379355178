import type {ControllerParams} from '@wix/yoshi-flow-editor'

const LIVE_VIDEO_APP_ID = '2608697e-067a-44e2-8250-8e390df5bf71'

export const authorizationGetter = (controller: ControllerParams) => {
  let authorization = controller.flowAPI.controllerConfig.appParams.instance
  onAuthorizationChanged(controller, newAuthorization => (authorization = newAuthorization))
  return () => authorization
}

export const onAuthorizationChanged = (controller: ControllerParams, callback: (authorization: string) => void) =>
  controller.flowAPI.controllerConfig.wixCodeApi.site.onInstanceChanged(
    event => callback(event.instance),
    LIVE_VIDEO_APP_ID,
  )
