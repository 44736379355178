import {createReducer} from '@reduxjs/toolkit'
import {ModalType} from '../interfaces'
import {showModal, hideModal} from '../actions/modals'

export interface Modals {
  modal: ModalType
}

const initialState: Modals = {
  modal: null,
}

export const modals = createReducer(initialState, builder => {
  builder
    .addCase(showModal, (state, {payload: modalType}) => {
      state.modal = modalType
    })
    .addCase(hideModal, state => {
      state.modal = null
    })
})
