import {createReducer} from '@reduxjs/toolkit'
import {markRecording, markNotRecording} from '../actions/recorder'

export interface RecorderState {
  recording: boolean
}

const initialState: RecorderState = {
  recording: false,
}

export const recorder = createReducer(initialState, builder => {
  builder
    .addCase(markRecording, state => {
      state.recording = true
    })
    .addCase(markNotRecording, state => {
      state.recording = false
    })
})
